import { Component, OnInit } from '@angular/core';
import { SIDEBAR_ITEM_NOTICE } from 'src/app/macro-community/interfaces/sidebar-macro-community.interface';
// import {ROUTES} from './menu-items';
import { DataService } from '../../services/data.service';
import { PublicService } from '../../services/public.service';
import { mainFeatures, ROUTES } from '../sidebar/menu-items';

@Component({
  selector: 'sidebar-macro-community',
  templateUrl: './sidebar-macro-community.component.html',
  styleUrls: ['./sidebar-macro-community.component.scss'],
})
export class SidebarMacroCommunityComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarNavSubItem = [];
  contability: boolean = false;

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(public dataService: DataService, public publicService: PublicService) {}

  // End open close
  async ngOnInit() {
    const user = this.dataService.getUser();
    const plan = this.dataService.getPlan();
    const role = user.roles ? user.roles[0] : 'superAdmin';
    const roles = user.roles ? user.roles : 'superAdmin';
    const isMacro = !!user.buildings[0].config?.macrocommunity?.enable;

    if(role !== 'customized'){
      this.sidebarNavSubItem.push({
        path: '/app-community',
        title: 'menu.home',
        icon: 'fa fa-home fa-2x',
        icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/house.svg',
        class: '',
        extralink: false,
        submenu: [],
      });
  
      this.addReserv();
  
      this.sidebarNavSubItem.push(SIDEBAR_ITEM_NOTICE);
  
      this.sidebarNavSubItem.push({
        path: '',
        title: 'menu.administration',
        icon: 'fa fa-desktop fa-2x',
        icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/screen-alt.svg',
        class: 'has-arrow',
        extralink: false,
        submenu: [
          {
            path: '/app-community/user',
            title: 'menu.tenants',
            // icon: 'fa fa-user fa-2x',
            icon: '',
            class: '',
            extralink: false,
            submenu: [],
          },
          {
            path: '/app-community/areas',
            title: 'menu.areas',
            icon: '',
            class: '',
            extralink: false,
            submenu: [],
          },
        ],
      });
  
      this.sidebarNavSubItem.push(ROUTES[mainFeatures.knowledgeCenter]);
    }else{
      for(let i = 0; i < user.features?.length; i++){
        this.sidebarNavSubItem.push(ROUTES[user.features[i].feature[0]]);
      }
    }
  }

  addReserv() {
    this.sidebarNavSubItem.push({
      path: '/app-community/reservations',
      title: 'menu.reservations',
      icon: 'fa fa-desktop fa-2x',
      icon2: 'https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/reservations.svg',
      class: '',
      extralink: false,
      submenu: [],
    });
  }
}
