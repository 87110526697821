import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
//Languages
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';

// Lib libphonenumber
import { PhoneNumberUtil } from 'google-libphonenumber';
import { CountryISO } from 'ngx-intl-tel-input';


@Injectable()
export class CommonService extends DataService {
  private phoneUtil = PhoneNumberUtil.getInstance();

  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
  }

  getDataDashboard() {
    return this.http
      .get(`${environment.apiUrl}/dashboard/getdata`)
      .toPromise()
      .then((res) => {
        console.log('ress: ', res);
      })
      .catch((err) => {
        console.log('errr: ', err);
        Promise.reject(err);
      });
  }

  triggerResize() {
    setTimeout(function () {
      var resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initEvent('resize', true, false);
      window.dispatchEvent(resizeEvent);
    }, 500);
  }

  validPhoneExceptions(control){
    // Colombian
    if(control.status == 'INVALID' && control.value && control.value.countryCode == 'CO' && control.value.nationalNumber){
      if(control.value.nationalNumber.length == 11 && control.value.nationalNumber.slice(0,3) == '324'){
        control.setErrors(null);
      }
    }
    return control;
  }

  getLanguagesList(): string[]{
    return [localeEs, localeEn].map((locale) => locale[0]) as string[];
  }

  getCountryISOFromPhoneNumber(phoneNumber: string): CountryISO | null {
    try {
      const number = this.phoneUtil.parse(phoneNumber);
      const regionCode = this.phoneUtil.getRegionCodeForNumber(number);
      return regionCode;
    } catch (error) {
      return null;
    }
  }

  getNationalNumber(phoneNumber: string): string | null {
    try {
      const number = this.phoneUtil.parse(phoneNumber);
      return this.phoneUtil.getNationalSignificantNumber(number);
    } catch (error) {
      return null;
    }
  }
}
