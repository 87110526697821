import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { FEATURES_DASHBOARD } from '../interface/features.interface';
import { ROUTES } from '../shared/sidebar/menu-items';
import { DataService } from './data.service';
import { MessagingService } from './messaging.service';

@Injectable()
export class AuthService extends DataService {
  messaging = firebase.messaging();
  currentMessage = new BehaviorSubject(null);
  userId = null;

  constructor(
    private http: HttpClient,
    public afAuth: AngularFireAuth,
    private messagingService: MessagingService,
    protected injector: Injector,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    protected store: Store<any>,
  ) {
    super(injector, store);
  }

  async doLogin(params) {
    try {
      return new Promise((resolve, reject) => {
        this.afAuth
          .signInWithEmailAndPassword(params.email, params.password)
          .then((res) =>
            res.user.getIdToken().then(async (token) => {
              const fcmToken: any = await this.messagingService.requestPermission();
              this.messagingService.receiveMessage();
              return this.doLoginKaus(params.email, token, fcmToken)
                .then((r: any) => {
                  let user = r.user;
                  let userBuilding = r.newFormat.userBuilding;
                  if(user.superUser && localStorage.getItem('usersSessions')?.length > 0) return reject({ inactive: true, message: 'toastrMessage.error.actionDeniedByRole' });
                  this.userId = user.id;
                  if (user.flag && !user.flag.changePassword) return reject({ notChangePassword: true });

                  const buildings = r.user.buildings;
                  if (buildings.length > 0 && !user.superUser) {
                    if (buildings[0].status !== 'active') return reject({ inactive: true, message: 'toastrMessage.error.buildingInactive' });
                    if (buildings[0].userStatus !== 'active') return reject({ inactive: true, message: 'toastrMessage.error.userInactive' });
                  }

                  user.token = r.token;
                  user.fbToken = token;
                  user.roles = buildings.length > 0 ? (buildings[0].roles ? buildings[0].roles : ['superAdmin']) : ['superAdmin'];
                  user.photoUrl = user.photoUrl;
                  user.isAuth = true;
                  user.features = this.mapFeatures(userBuilding.features);
                  this.setUser(user);
                  const language:string = user.i18n;
                  if(language) this.setLanguage(language.slice(0,2));
                  return resolve(true);
                })
                .catch((err) => {
                  console.log('-> err: ', err);
                  return reject(err);
                });
            }),
          )
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    } catch (err) {
      console.log('-- err: ', err);
    }
  }

  async doLoginAdonis(email, fbToken, fcmToken) {
    const headers = new HttpHeaders().set('x-platform', 'webpage');
    return this.http
      .post(`${environment.apiUrl}/user/login`, { email, fbToken, fcmToken }, { headers })
      .toPromise()
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  async doLoginKaus(email: string, fbToken: string, fcmToken?: string) {
    const headers = new HttpHeaders().set('x-platform', 'webpage');
    let body: any = {
      email: email,
      fbToken: fbToken,
    };
    if (!!fcmToken) body = { fcmToken: fcmToken, ...body };
    return this.http
      .post(`/api/auth/v1/login`, body, { headers })
      .toPromise()
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  doLogoutFirebase() {
    this.afAuth.signOut().then(() => {
      localStorage.removeItem('currentUser');
      this.router.navigate(['/authentication/login']);
    });
  }

  async logoutV2() {
    const fcmToken = await this.messagingService.requestPermission();
    this.doLogoutV2(fcmToken).subscribe(
      (res) => {
        this.afAuth
          .signOut()
          .then(() => {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('usersSessions');
            this.router.navigate(['/authentication/login']);
          })
          .catch((err) => {
            this.handleLogoutError(err);
          });
      },
      (err) => this.handleLogoutError(err),
    );
  }

  doLogoutV2(fcmToken) {
    return this.http.post(`${environment.apiUrl}/auth/v1/logout`, { fcmToken });
  }

  handleLogoutError(err) {
    console.error(err);
    this.toastr.error(this.translate.instant('authentication.error.logout'));
  }

  async doLogout() {
    this.doLogoutAdonis().then(() => {
      this.afAuth
        .signOut()
        .then(() => {
          localStorage.removeItem('currentUser');
          this.router.navigate(['/authentication/login']);
        })
        .catch((err) => {
          // An error happened.
          console.log('>>Error: ', err);
        });
    });
  }

  async doLogoutAsync() {
    return this.doLogoutAdonis().then(() => {
      localStorage.removeItem('currentUser');
      return this.afAuth.signOut();
    });
  }

  async doLogoutAdonis() {
    const fcmToken = await this.messagingService.requestPermission();
    return this.http
      .post(`${environment.apiUrl}/user/logout`, { fcmToken })
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  sendPasswordResetEmail(email: string): Promise<string | void> {
    return new Promise((resolve, reject) => {
      const actionCodeSettings = {
        url: `${environment.baseUrl}/#/authentication/login`,
        handleCodeInApp: false,
      };
      this.afAuth
        .sendPasswordResetEmail(email, actionCodeSettings)
        .then((r) => {
          return resolve();
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  resetPassword(currentPassword, newPassword) {
    return new Promise((resolve, reject) => {
      const user: any = this.afAuth.currentUser;
      this.validCurrentPassword(user, currentPassword)
        .then(() => {
          user
            .updatePassword(newPassword)
            .then(() => {
              this.changeFlagPassword(this.userId, { changePassword: true })
                .then(() => {
                  resolve(true);
                })
                .catch((err) => reject(err));
            })
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });
  }

  sendResetPassword(currentPassword) {
    return new Promise((resolve, reject) => {
      const user: any = this.afAuth.currentUser;
      this.validCurrentPassword(user, currentPassword)
        .then(() => {
          this.sendPasswordResetEmail(user.email)
            .then(() => {
              resolve(true);
            })
            .catch(() => reject(false));
        })
        .catch((err) => reject(err));
    });
  }

  validCurrentPassword(user: any, currentPassword) {
    const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  changeFlagPassword(id, params) {
    return this.http
      .put(`${environment.apiUrl}/user/update-change-password/${id}`, params)
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  mapFeatures(featuresList){
    let features = []
    if(!featuresList) return features
    featuresList.forEach(feature => {
      const featureDashboard = FEATURES_DASHBOARD.find(element => element.code === feature.feature.code);
      if(featureDashboard){
        features.push({
          feature: featureDashboard.feature,
          enable: feature.enable
        })
      } 
    })
    return features
  }

  getUserFirstRoute(user){
    let route = '/app'
    const feature = user.features.find(feature => feature.enable === true)
    if (!feature || !feature.feature[0]) return route
    route = ROUTES[feature.feature[0]].path
    return route;
  }
}
