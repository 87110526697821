import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NoticeInteractions } from '../interface/noticesV2.interface';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class NoticesV2Service extends DataService {
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
  }

  getNotices(query: { page: string; rowsPerPage: string; sortOrder?: string; sortField?: string; status?: string; search?: string; typeNotice?: string }) {
    let params = new HttpParams();
    for (const iterator in query) {
      if (query[iterator]) params = params.set(iterator, query[iterator]);
    }
    return this.http.get(`${environment.apiUrl}/admin/v1/notice/dashboard`, { params });
  }

  getAndReadingNotice(noticeId: string) {
    return this.http.get(`${environment.apiUrl}/admin/v1/notice/dashboard/${noticeId}`);
  }

  fetchListNotices(page, status, search?) {
    const params = search ? `&${search}` : '';
    return this.http
      .get(`${environment.apiUrl}/v2/notices?isDashboard=true&page=${page}&rowsPerPage=10&sortOrder=-1&sortField=created_at&status=${status}&search=${search}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  fetchNoticesById(id) {
    return this.http
      .get(`${environment.apiUrl}/v2/notices/${id}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  fetchReceivers() {
    return this.http
      .get(`${environment.apiUrl}/v2/notices/receivers`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  fetchInteractionList(): Observable<NoticeInteractions[]> {
    return this.http.get(`${environment.apiUrl}/admin/v1/notice/emojis-iterations`).pipe(map((interactions: any) => interactions.data));
  }

  uploadListImages(images) {
    return this.http
      .post(`${environment.apiUrl}/v2/notices/upload-base64`, images)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  uploadListFiles(files) {
    return this.http
      .post(`${environment.apiUrl}/admin/v1/notice/upload-files`, files)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  uploadFiles(file: FormData) {
    return this.http.post(`${environment.apiUrl}/admin/v1/notice/file`, file).pipe(map((file: any) => file.data));
  }

  //Quiero eliminar esta funcion
  uploadImagesStorage(imageBase64: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/admin/v1/notice/upload-images`, { imageBase64 }).pipe(map((file) => file.data));
  }

  deleteFiles(nameServe: string) {
    return this.http.delete(`${environment.apiUrl}/admin/v1/notice/file/nameServe`.replace('nameServe', encodeURIComponent(nameServe)));
  }

  storeNotices(data) {
    return this.http
      .post(`${environment.apiUrl}/admin/v1/notice`, data)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getInteractionNotices(id: string, query: { page: number; rowsPerPage: number; sortOrder?: number; sortField?: string; searchUser?: string }) {
    let params = new HttpParams();
    for (const iterator in query) {
      if (query[iterator]) params = iterator == 'searchUser' ? params.set('search', query[iterator]) : params.set(iterator, query[iterator]);
    }

    return this.http
      .get(`${environment.apiUrl}/admin/v1/users/notice/${id}/interactions`, { params })
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getListInteractionNotices(id: string, page, search?) {
    return this.http
      .get(`${environment.apiUrl}/v2/notices/${id}/interactions?page=${page}&rowsPerPage=10&sortOrder=1&sortField=updated_at&search=${search}&user`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateStatus(id: string, body) {
    return this.http.put(`${environment.apiUrl}/admin/v1/notice/${id}/status`, body);
  }
}
