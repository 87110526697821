import { mainFeatures } from "../shared/sidebar/menu-items";

export class FeaturesMenuItems {
  code: FeaturesDBCode;
  feature: mainFeatures[];
}

export enum FeaturesDBCode {
  fastPass = 'F01',
  comunily = 'F02',
  reservations = 'F03',
  default = 'F04',
  delivery = 'F05',
  notices = 'F06',
  providers = 'F07',
  chats = 'F08',
  frequentAccess = 'F09',
  visits = 'F10',
  parcel = 'F11',
  accountStatements = 'F12',
  pqrs = 'F13',
  accessManagement = 'F14',
  securityCenters = 'F15',
  accessControlMacroAdmin = 'F17',
  accessControlMacroBusiness = 'F18',
  securityCenterMacroAdmin = 'F19',
  securityCenterMacroBusiness = 'F20'
}

export enum Features {
  fastPass = 'fastPass',
  comunily = 'comunily',
  reservations = 'reservations',
  default = 'default',
  delivery = 'delivery',
  notices = 'notices',
  providers = 'providers',
  chats = 'chats',
  frequentAccess = 'frequentAccess',
  visits = 'visits',
  parcel = 'parcel',
  accountStatements = 'accountStatements',
  pqrs = 'pqrs',
  accessManagement = 'accessManagement',
  securityCenters = 'securityCenters',
  accessControlMacroAdmin = 'accessControlMacroAdmin',
  accessControlMacroBusiness = 'accessControlMacroBusiness',
  securityCenterMacroAdmin = 'securityCenterMacroAdmin',
  securityCenterMacroBusiness = 'securityCenterMacroBusiness'
}

export const FEATURES_DASHBOARD_PANAMA_PACIFICO_ANDMIN: FeaturesMenuItems[] =
[
  { code: FeaturesDBCode.accessControlMacroAdmin, feature: [mainFeatures.accessControlMacroAdmin]},
  { code: FeaturesDBCode.securityCenterMacroAdmin, feature: [mainFeatures.securityCenterMacroAdmin]}
];

export const FEATURES_DASHBOARD_PANAMA_PACIFICO_BUSINESS: FeaturesMenuItems[] =
[
  { code: FeaturesDBCode.accessControlMacroBusiness, feature: [mainFeatures.accessControlMacroBusiness]},
  { code: FeaturesDBCode.securityCenterMacroBusiness, feature: [mainFeatures.securityCenterMacroBusiness]}
];

export const FEATURES_EXCLUDE_SIDEBAR: mainFeatures[] = []

export const FEATURES_DASHBOARD: FeaturesMenuItems[] =
[
  { code: FeaturesDBCode.reservations, feature: [mainFeatures.reservations]},
  { code: FeaturesDBCode.notices, feature: [mainFeatures.notices]},
  { code: FeaturesDBCode.chats, feature: [mainFeatures.chats]},
  { code: FeaturesDBCode.frequentAccess, feature: [mainFeatures.frequentAccess]},
  { code: FeaturesDBCode.visits, feature: [mainFeatures.visits]},
  { code: FeaturesDBCode.accountStatements, feature: [mainFeatures.reservations]},
  { code: FeaturesDBCode.accessManagement, feature: [mainFeatures.accessManagement]},
  { code: FeaturesDBCode.securityCenters, feature: [mainFeatures.securityCenters]},
  ...FEATURES_DASHBOARD_PANAMA_PACIFICO_ANDMIN,
  ...FEATURES_DASHBOARD_PANAMA_PACIFICO_BUSINESS
];
